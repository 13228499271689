<template>
  <div class="systemConfig">
    <a-row>
      <a-col :xl="3" :md="6" :sm="8">
        <div class="title">{{ $t('sysAdmin.importProduct') }}：</div>
      </a-col>
      <a-col :xl="3" :md="6" :sm="8">
        <a-button @click="asyncTypesense">{{ $t('sysAdmin.Async') }}</a-button>
      </a-col>
    </a-row>
    <div class="full_screen" v-if="loading">
      <a-spin size="large" class="spin" />
    </div>
  </div>
</template>
<script>
  import { ref, onMounted } from 'vue'
  import useRmaRepositories from "@/composables/useRmaRepositories"
  import { useI18n } from 'vue-i18n';
  import { notification } from 'ant-design-vue';

  export default {
    name: 'SystemConfig',
    components: {},
    data () {
      return {}
    },
    setup() {
      const { importProductTypesense } = useRmaRepositories()
      const { t ,locale } = useI18n({ useScope: 'global' })
      const loading = ref(false)
      onMounted(async () => {})

      const asyncTypesense = async () => {
        loading.value = true
        const result = await importProductTypesense()
        loading.value = false
        if(result.status = '60001' && result.success == false){
          notification.error({
            message: 'Error',
            description: `Encounter error while importing product info from NAV.`
          })
        }else {
          notification.success({
            message: 'Success',
            description: `Importing product info from NAV successfully.`
          })
        }
      }

      return {
        asyncTypesense,
        loading,
        t,
        locale
      }
    }
  }
</script>

<style lang="scss" scoped>
  .systemConfig {
    padding: 10px;
    width: 100%;
    min-height: 100%;
    position: relative;

    .title {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .full_screen {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.3);

      .spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
</style>